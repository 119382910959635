import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils/dist/jszip-utils.min.js';

import { fetchApi } from '@/helpers/api';

import { ISelectedDocument } from '../components/DataGrid';
import { ApiUrl, PageUrlParam } from '../constants';
import { urlHelper } from '../helpers/url';
import { httpClient } from './HttpClient';
import multiDownload from './multipleDownload';
import { FileInfoV2, FileMetadataV2 } from './SearchService';

export interface IDownloadResponse {
  data: {
    extension: 'pdf' | 'zip';
    name: string;
    path: string;
    size: number;
  };
}

export interface OcrPage {
  h: number;
  images: OcrImage[];
  p: number;
  rotation?: number;
  w: number;
}

export interface PdfOcrData {
  positions: OcrPage[];
}

interface OcrImage {
  lines: OcrLine[];
}

type OcrLine = OcrWord[];

interface OcrWord {
  bb: number[];
  t: string;
}

const MAX_DOWNLOAD_FILE_ATTEMPTS = 3;

export type TypeUUID = FileInfoV2['uuid'];

/**
 * Fetch the content and return the associated promise.
 * @param {String} url the url of the content to fetch.
 * @return {Promise} the promise containing the data.
 */
function urlToPromise(url: string) {
  return new Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(url, function (err: Error, data: any) {
      if (err instanceof Error) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

const zip = new JSZip();

class FileService {
  downloadFile(
    file: FileInfoV2,
    queries: string[],
    getFullFile = false,
    abortController = new AbortController(),
    attempts = 1,
  ): [AbortController, Promise<null | Response>] {
    let url = `${ApiUrl.OCR_EXTRACTION_V2.replace('{uuid}', file.uuid)
      .replace('{preview_numbers}', `1`)
      .replace(
        '{full_preview}',
        String(!!getFullFile),
      )}?page_preview_numbers=1`;

    if (queries.length > 0) {
      // queries is must-have so we don't need to validate
      const _queries = queries
        .map((query: string) => {
          return `${PageUrlParam.QUERIES}=${encodeURIComponent(query)}`;
        })
        .join('&');

      url = `${url}&${_queries}`;
    }

    const downloadRequest = fetchApi(url, {
      headers: {
        [`Content-Type`]: `application/json`,
      },
      method: 'GET',
      signal: abortController.signal,
    })
      .then(async (response) => {
        if ([0, 200].includes(response.status)) {
          return response;
        } else if (
          response.status === 403 &&
          attempts < MAX_DOWNLOAD_FILE_ATTEMPTS
        ) {
          // const refreshedUrl = await this.refreshFileUrl(file);
          const [, request] = await this.downloadFile(
            file,
            queries,
            !!getFullFile,
            abortController,
            attempts + 1,
          );
          return request;
        }

        return Promise.reject(new Error(response.statusText));
      })
      .catch((error) => {
        console.error(`Aborted ${file.uuid}: ${error}`);
        return null;
      });

    return [abortController, downloadRequest];
  }

  downloadFileMetadata(uuid: TypeUUID, attempts = 1): Promise<null | Response> {
    const _fileMetadataUrl = `${ApiUrl.FILE_METADATA.replace('{uuid}', uuid)}`;

    const downloadRequest = fetchApi(_fileMetadataUrl)
      .then(async (response) => {
        if ([0, 200].includes(response.status)) {
          return response;
        } else if (
          response.status === 403 &&
          attempts < MAX_DOWNLOAD_FILE_ATTEMPTS
        ) {
          const request = await this.downloadFileMetadata(uuid, attempts + 1);
          return request;
        }

        return Promise.reject(new Error(response.statusText));
      })
      .catch((error) => {
        console.error(`Aborted ${uuid as string}: ${error}`);
        return null;
      });
    return downloadRequest;
  }

  read(
    file: FileInfoV2,
    queries: string[],
    getFullFile = false,
    updateProgress?: (progress: number) => void,
  ): [
    AbortController | undefined,
    // Promise<[Blob | undefined, PdfOcrData | undefined]>,
    Promise<[Blob | undefined, PdfOcrData | undefined]>,
  ] {
    const [abortController, downloadRequest] = this.downloadFile(
      file,
      queries,
      getFullFile,
    );

    const readFileAsync = new Promise<
      [Blob | undefined, PdfOcrData | undefined]
    >(async (resolve) => {
      try {
        // abortController.signal.onabort = async () => {
        //   try {
        //     await reader?.cancel();
        //   } catch (error) {
        //     console.log(`Aborted ${file.uuid}`);
        //   }
        // };

        // const response = await downloadRequest;

        // const reader = response?.body?.getReader();

        // if (!response || !reader) {
        //   resolve([undefined, undefined]);
        //   return;
        // }

        // const contentLength = +(response?.headers?.get('Content-Length') || 0);
        // const chunks = new Uint8Array(contentLength);
        // let receivedLength = 0;

        // while (true) {
        //   const { done, value } = await reader.read();

        //   if (done || !value) {
        //     break;
        //   }

        //   chunks.set(value, receivedLength);
        //   receivedLength += value?.length || 0;

        //   updateProgress &&
        //     updateProgress(Math.round((receivedLength / contentLength) * 100));
        // }

        // const { uuid } = file;
        // const blob = new Blob([chunks]);

        // const zip = await JSZip.loadAsync(blob);
        // const [pdfBlob, ocrDataStr] = await Promise.all([
        //   zip?.file(`ocr_${uuid}.pdf`)?.async('blob') as Promise<Blob>,
        //   zip?.file(`${uuid}.json`)?.async('string') as Promise<string>,
        // ]);

        // const { positions } = await response?.json();

        // const { data }: IDownloadResponse = await httpClient.getWithProgress(
        //   {
        //     url: urlHelper.getDownloadFileUrl(file.uuid),
        //     requiresToken: true,
        //   },
        //   updateProgress,
        // );

        const [downloadedFile, pdfBlob] = await Promise.all([
          downloadRequest.then((res) => res?.json()),
          httpClient
            .getWithProgress(
              {
                requiresToken: true,
                url: urlHelper.getDownloadFileUrl(file.uuid),
              },
              updateProgress,
            )
            .then(({ data }) => fetch(data.path).then((res) => res.blob())),
        ]);

        const { positions } = downloadedFile;

        // const pdfBlob = await fetch(data.path).then((res) => res.blob());
        // const { positions } = JSON.parse(response.body);

        resolve([pdfBlob, { positions }]);
      } catch (error) {
        resolve([undefined, undefined]);
      }
    });

    return [abortController, readFileAsync];
  }

  readMetadata(uuid: TypeUUID): Promise<FileMetadataV2 | undefined> {
    const downloadRequest = this.downloadFileMetadata(uuid);

    const readFileAsync = new Promise<FileMetadataV2 | undefined>(
      async (resolve) => {
        try {
          const response = await downloadRequest;
          const { data } = await response?.json();

          resolve(data);
        } catch (error) {
          console.error(error);
          resolve(undefined);
        }
      },
    );

    return readFileAsync;
  }

  // said Quan: There are some pdfs we are not able to scan through/to do OCR, so we need to preview the original pdf
  async readPdfWithoutOCR(
    { uuid }: FileInfoV2,
    updateProgress?: (progress: number) => void,
  ): Promise<string> {
    let rawPdfUrl = '';
    try {
      const { data }: IDownloadResponse = await httpClient.getWithProgress(
        {
          requiresToken: true,
          url: urlHelper.getDownloadFileUrl(uuid),
        },
        updateProgress,
      );

      rawPdfUrl = data?.path || '';
    } catch (error) {
      console.error(error);
    } finally {
      return rawPdfUrl;
    }
  }

  async refreshFileUrl({ uuid }: FileInfoV2): Promise<string> {
    const { refresh_url: refreshUrl } = await httpClient.get({
      requiresToken: true,
      url: urlHelper.getRefreshFileUrl(uuid),
    });
    return refreshUrl;
  }

  async saveFile(uuid: string): Promise<void> {
    try {
      const { data }: IDownloadResponse = await httpClient.get({
        requiresToken: true,
        url: urlHelper.getDownloadFileUrl(uuid),
      });

      const link = document.createElement('a');
      link.download = `${data.name}.${data.extension}`;
      link.href = `${data?.path}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  }

  async saveMultipleFiles(
    selectedDocuments: ISelectedDocument[],
  ): Promise<void> {
    try {
      const downloadableUrls: string[] = selectedDocuments.map(
        ({ uuid }: ISelectedDocument) => {
          return urlHelper.getDownloadFileUrl(uuid);
        },
      );

      const promises = downloadableUrls.map((url: string) => {
        return httpClient.get({
          requiresToken: true,
          url,
        });
      });

      // Quan said: why Promise.all, not allSettled? to make sure we have all the responses in one call
      await Promise.all(promises).then(
        async (responses: IDownloadResponse[]) => {
          // download actual pdfs
          const azureBlobStoragePdfDownloadableUrls = responses.map(
            (response: IDownloadResponse) => {
              return response.data.path;
            },
          );

          await multiDownload(azureBlobStoragePdfDownloadableUrls);
          // const azureBlobStoragePdfDownloadableUrls = responses.map((response: IDownloadResponse) => {
          // 	return httpClient.get({
          // 		url: response.data.path,
          // 	});
          // });

          // await Promise.all(azureBlobStoragePdfDownloadableUrls).then((pdfResponses) => {
          // 	const zip = new JSZip();
          // 	pdfResponses.forEach((response: any, index: number) => {
          // 		console.log({ response });
          // 		// zip.file(`${uuidList[index]}.pdf`, response, { binary: true });
          // 		// zip.file(`${uuidList[index]}.pdf`, data.path, { binary: true });
          // 	});
          // 	// zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
          // 	// 	const link = document.createElement('a');
          // 	// 	link.download = `download.zip`;
          // 	// 	link.href = URL.createObjectURL(content);
          // 	// 	document.body.appendChild(link);
          // 	// 	link.click();
          // 	// 	document.body.removeChild(link);
          // 	// });
          // });
        },
      );
    } catch (error) {
      console.error(error);
    }
  }

  async saveMultipleFilesAsZip(
    selectedDocuments: ISelectedDocument[],
  ): Promise<void> {
    try {
      const downloadableUrls: string[] = selectedDocuments.map(
        ({ uuid }: ISelectedDocument) => {
          return urlHelper.getDownloadFileUrl(uuid);
        },
      );

      // fetch metadata of pdf files
      const promises = downloadableUrls.map((url: string) => {
        return httpClient.get({
          requiresToken: true,
          url,
        });
      });

      await Promise.all(promises).then(
        async (responses: IDownloadResponse[]) => {
          // download actual pdfs
          const azureBlobStoragePdfDownloadableUrls = responses.map(
            (response: IDownloadResponse) => {
              return response.data.path;
            },
          );

          for (let i = 0; i < azureBlobStoragePdfDownloadableUrls.length; i++) {
            const url = azureBlobStoragePdfDownloadableUrls[i];
            zip.file(
              `${selectedDocuments[i].document_number}.pdf`,
              urlToPromise(url) as any,
              {
                binary: true,
              },
            );
          }

          zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
            const link = document.createElement('a');
            link.download = `download.zip`;
            link.href = URL.createObjectURL(content);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        },
      );
    } catch (error) {
      console.error(error);
    }
  }
}

export const fileService = new FileService();
