import { Alert, Fade } from '@mui/material';
import { useSetAtom } from 'jotai';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PdfHighlight } from 'react-pdf-highlighter';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { useTrackEvents } from '@/analytics/use-track-events';
import { documentListViewTypeAtom } from '@/atoms/document';

import Pagination from '../../components/Pagination';
import {
  ApiDefaultValue,
  colors,
  PageUrlParam,
  TypeView,
  VIEW_OPTION,
} from '../../constants';
import { usePagination } from '../../hooks/usePagination';
import { FileInfoV2, SearchResponseV2 } from '../../services/SearchService';
import { GridIcon, ListIcon, WarningIcon } from '../icons';
import { PdfDetailData, SearchTerm } from '../pdf/pdf';
import { PdfDetail } from '../pdf/PdfDetail';
import DocumentModal from './DocumentModal';
import NoResult from './NoResult';
import { ProjectList } from './ProjectList';
import SearchResultView from './SearchResultView';

interface SearchResultProps {
  isFetching: boolean;
  searchData: SearchResponseV2;
  searchError: boolean;
  tags: Array<string>;
  view: TypeView;
}

export function SearchResult(props: SearchResultProps): ReactElement {
  const { isFetching, searchData, searchError, tags, view } = props;

  const { t } = useTranslation();
  const {
    currentPage,
    handleClick,
    handleMoveLeft,
    handleMoveRight,
    pageLimit,
    pageNumbers,
  } = usePagination();

  const [searchParams, setSearchParams] = useSearchParams();

  const setDocumentListViewType = useSetAtom(documentListViewTypeAtom);

  const [showPdfModal, setShowPdfModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState<PdfDetailData>();
  const [documentModalOpen, setDocumentOpen] = useState(false);

  const { trackDocumentPreview } = useTrackEvents();

  const onViewPdf = (
    file: FileInfoV2,
    searchTerms: SearchTerm[],
    highlight?: PdfHighlight,
  ) => {
    if (showPdfModal) {
      return;
    }

    setSelectedPdf({ file, highlight, searchTerms });
    setShowPdfModal(true);

    trackDocumentPreview(file.document_number);
  };

  useEffect(() => {
    const documentId = searchParams.get('document');

    if (documentId) {
      const document = searchData.data.find((file) => file.uuid === documentId);

      if (document) {
        const searchTerms = tags.map((value, index, _) => {
          return { color: colors[index].rgba, term: value };
        });

        setSelectedPdf({ file: document, searchTerms });
        setShowPdfModal(true);
      }
    }
  }, [searchData.data, searchParams, tags]);

  const onClosePdfDetail = useCallback(() => {
    setShowPdfModal(false);
    setSelectedPdf(undefined);

    setSearchParams((prev) => {
      prev.delete('document');
      prev.delete('page');
      return prev;
    });
  }, [setSearchParams]);

  const searchResultsTotalText = useMemo(() => {
    if (view === VIEW_OPTION.TABLE && searchData.total_count >= 2000) {
      return t('search_result_text_first_2000_rows');
    }

    if (!searchData?.total_count || searchData.total_count === 0) {
      return t('search_result_text_no_match_found_for');
    }

    return t('search_result_text_total_results_found', {
      items: new Intl.NumberFormat().format(searchData.total_count),
    });
  }, [view, searchData.total_count, t]);

  useEffect(() => {
    return () => {
      setDocumentListViewType('');
    };
  }, [setDocumentListViewType]);

  const { trackViewChange } = useTrackEvents();

  const [open, setOpen] = useState(() =>
    sessionStorage.getItem('document-alert')
      ? sessionStorage.getItem('document-alert') === 'open'
      : true,
  );

  if (searchError) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <WarningIcon height={80} width={80} />
        <h3>{t('search_result_text_something_wrong')}</h3>
        <p>{t('search_result_text_no_results_try_again')}</p>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center relative mb-3 mt-3 justify-between">
        <div className="mr-1 text-nowrap">{searchResultsTotalText}</div>

        <Fade in={open}>
          <Alert
            onClose={() => {
              sessionStorage.setItem('document-alert', 'closed');
              setOpen(false);
            }}
            severity="info"
          >
            Hammerhead documents (4193) - are now live on MODEC Bing
          </Alert>
        </Fade>

        <div className="d-flex justify-content-center align-items-center ml-4 flex-row">
          <button
            onClick={() => {
              setDocumentListViewType(VIEW_OPTION.THUMBNAIL);
              searchParams.set(PageUrlParam.VIEW_OPTION, VIEW_OPTION.TABLE);
              searchParams.set(
                PageUrlParam.LIMIT,
                String(ApiDefaultValue.LIMIT_BY_TABLE_VIEW),
              );
              searchParams.set(PageUrlParam.OFFSET, '0');
              setSearchParams(searchParams);
              trackViewChange(VIEW_OPTION.TABLE);
            }}
          >
            <ListIcon
              className={twMerge(
                'ic ic-large',
                view === VIEW_OPTION.TABLE && 'active',
              )}
            />
          </button>

          <button
            className="ml-3"
            onClick={() => {
              setDocumentListViewType(VIEW_OPTION.THUMBNAIL);
              searchParams.set(PageUrlParam.VIEW_OPTION, VIEW_OPTION.THUMBNAIL);
              searchParams.set(
                PageUrlParam.LIMIT,
                String(ApiDefaultValue.LIMIT_BT_THUMBNAIL_VIEW),
              );
              setSearchParams(searchParams);
              trackViewChange(VIEW_OPTION.THUMBNAIL);
            }}
          >
            <GridIcon
              className={twMerge(
                'ic ic-large',
                view === VIEW_OPTION.THUMBNAIL && 'active',
              )}
            />
          </button>

          <div className="relative ml-3 h-12 w-[450px]">
            <ProjectList />
          </div>
        </div>
      </div>

      {!searchParams.get('q') || searchData.data?.length === 0 ? (
        <NoResult />
      ) : (
        <>
          <div
            className={twMerge(
              view === VIEW_OPTION.THUMBNAIL
                ? 'grid flex-grow grid-rows-2 justify-center gap-4 lg:grid-cols-3'
                : '',
            )}
          >
            <SearchResultView
              data={searchData.data}
              onViewPdf={onViewPdf}
              showPdfModal={showPdfModal}
              tags={tags}
              view={view}
            />
          </div>

          <div className="d-flex justify-content-center mt-2">
            {view === VIEW_OPTION.THUMBNAIL && pageNumbers.length > 1 && (
              <Pagination
                currentPage={currentPage}
                handleClick={handleClick}
                handleMoveLeft={handleMoveLeft}
                handleMoveRight={handleMoveRight}
                pageLimit={pageLimit}
                pageNumbers={pageNumbers}
              />
            )}
          </div>

          <DocumentModal
            onClose={() => {
              setDocumentOpen(false);
            }}
            open={documentModalOpen}
          />

          {selectedPdf && (
            <PdfDetail
              onHide={onClosePdfDetail}
              queries={tags}
              show={showPdfModal}
              {...selectedPdf}
            />
          )}
        </>
      )}
    </div>
  );
}
