import '@fontsource/noto-sans';
import 'devextreme/dist/css/dx.dark.css';
import './assets/scss/main.scss';
import './index.css';
import './localization/i18n';

import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import Clarity from '@microsoft/clarity';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { appInsights, reactPlugin } from './analytics/application-insights';
import { msalInstance } from './helpers/msal';
import { router } from './router';
import { clarityService } from './services';

const queryClient = new QueryClient();

appInsights.loadAppInsights();

process.env.REACT_APP_CLARITY_PROJECT_ID &&
  Clarity.init(process.env.REACT_APP_CLARITY_PROJECT_ID);

msalInstance
  .initialize()
  .then(() => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);

      if (accounts[0].name && accounts[0].username) {
        clarityService.init(accounts[0].name, accounts[0].username);
      }
    }

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;

        msalInstance.setActiveAccount(account);

        appInsights.setAuthenticatedUserContext(account.username);
      }
    });

    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
            </QueryClientProvider>
          </AppInsightsContext.Provider>
        </MsalProvider>
      </React.StrictMode>,
    );
  })
  .catch(console.error);
