import { isUndefined, throttle } from 'lodash';
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Modal, ProgressBar, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PdfHighlight } from 'react-pdf-highlighter';

import unlock3DLiteService from '@/services/Unlock3DLiteService';

import { FitView } from '../../constants';
import { trimSearchTermForPdf } from '../../helpers/utils';
import { useFile } from '../../hooks/useFile';
import { PdfOcrData } from '../../services/FileService';
import { FileInfoV2 } from '../../services/SearchService';
import { DetailHeader } from '../commons/DetailHeader';
import View3DModelButtonFloat from '../commons/View3DModelButtonFloat';
import { BrokenImageIcon } from '../icons';
import { SearchTerm } from './pdf';
import { PdfViewer } from './PdfViewer';
import { PdfViewerUtility } from './PdfViewer.interface';

interface PdfDetailProps {
  file: FileInfoV2;
  highlight?: PdfHighlight;
  onHide: () => void;
  queries: string[];
  searchTerms: SearchTerm[];
  show: boolean;
}

export function PdfDetail(props: PdfDetailProps) {
  const { file, highlight, queries, searchTerms } = props;
  const { t } = useTranslation();
  const [pdfUtil, setPdfUtil] = useState<PdfViewerUtility>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [selectedSearchTerms, setSelectedSearchTerms] = useState<
    Record<string, boolean>
  >({});
  const [matchedSearchTerms, setMatchedSearchTerms] = useState<
    Record<string, number>
  >({});
  const [orderedMatchedSearchTerms, setOrderedMatchedSearchTerms] = useState<
    SearchTerm[]
  >([]);
  const [showed, setShowed] = useState(false);
  const [currentFindTermIndex, setCurrentFindTermIndex] = useState<
    Record<string, number>
  >({});
  const [scale, setScale] = useState<number>();
  const { getFile, getRawFile } = useFile();
  const [downloadFileProgress, setDownloadFileProgress] = useState(0);
  const [pdfUrl, setPdfUrl] = useState<string>();
  const [ocrData, setOcrData] = useState<PdfOcrData>();
  const [loadingState, setLoadingState] = useState<
    'error' | 'loading' | 'success'
  >('loading');
  const [modalBodyHeight, setModalBodyHeight] = useState<number | string>(
    '100%',
  );
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const [view3DModelButtonEnabled, setView3DModelButtonEnabled] =
    useState(false);
  const [view3DModelUrl, setView3DModelUrl] = useState<null | string>(null);
  const onScaleChange = useCallback((value: number) => {
    setScale(Math.round(value * 100));
  }, []);

  const onShow = () => {
    setShowed(true);
  };

  const onHide = () => {
    setShowed(false);
    props.onHide();
  };

  useEffect(() => {
    const getPdfFile = async () => {
      let _pdfUrl: string = '';

      if (file.uuid && file.is_ocr_content_extracted) {
        const [pdfUrl, _ocrData] = await getFile(
          file,
          queries,
          true,
          setDownloadFileProgress,
        );

        _pdfUrl = pdfUrl;

        if (_ocrData) {
          setOcrData(_ocrData);
        }
      } else {
        _pdfUrl = await getRawFile(file, setDownloadFileProgress);
      }

      if (!_pdfUrl) {
        setLoadingState('error');

        return;
      }

      setLoadingState('success');
      setPdfUrl(_pdfUrl);
    };

    getPdfFile();
  }, [file, file?.uuid, getFile, getRawFile, queries]);

  useEffect(() => {
    if (!showed || !modalHeaderRef.current) {
      return;
    }

    // Set modal by height manually
    // Workaround to fix weird "mix-blend-mode" of pdf text layer inside full modal
    const modalRect =
      modalHeaderRef.current.parentElement?.parentElement?.getBoundingClientRect();
    const headerRect = modalHeaderRef.current.getBoundingClientRect();

    if (modalRect && headerRect) {
      setModalBodyHeight(modalRect.height - headerRect.height);
    }
  }, [showed, modalHeaderRef]);

  useEffect(() => {
    setIsProcessing(!!highlight);
    setCurrentFindTermIndex({});
  }, [props.show]);

  const setPdfViewerUtility = useCallback((pdfUtil: PdfViewerUtility) => {
    setPdfUtil(pdfUtil);
  }, []);

  useEffect(() => {
    const _matchedSearchTermsLocal = pdfUtil?.getMatchSearchTerms();

    if (_matchedSearchTermsLocal) {
      setMatchedSearchTerms(_matchedSearchTermsLocal);

      const orderedMatchedTerms = searchTerms?.filter((item: SearchTerm) => {
        return _matchedSearchTermsLocal[trimSearchTermForPdf(item.term)] > 0;
      });

      setOrderedMatchedSearchTerms(orderedMatchedTerms);

      const defaultSelectedSearchTerms = orderedMatchedTerms.reduce(
        (acc: Record<string, boolean>, item: SearchTerm) => ({
          ...acc,
          [trimSearchTermForPdf(item.term)]: true,
        }),
        {},
      );
      setSelectedSearchTerms(defaultSelectedSearchTerms);
    }
  }, [pdfUtil, searchTerms]);

  const toggleKeyword =
    (keyword: string): MouseEventHandler =>
    () => {
      pdfUtil?.toggleHighlight(keyword, !selectedSearchTerms[keyword]);
      setSelectedSearchTerms((prevState: Record<string, boolean>) => ({
        ...prevState,
        [keyword]: !prevState[keyword],
      }));
    };

  const handleNextHighlight =
    (searchTerm: string): MouseEventHandler =>
    () => {
      pdfUtil?.nextHighlight(searchTerm);
    };

  const handlePreviousHighlight =
    (searchTerm: string): MouseEventHandler =>
    () => {
      pdfUtil?.prevHighlight(searchTerm);
    };

  const onFocusHighlightChange = useCallback((highlight?: PdfHighlight) => {
    setIsProcessing(false);
    highlight &&
      setCurrentFindTermIndex(() => {
        return {
          [highlight.content.text]: highlight.position.indexInFile + 1,
        };
      });
  }, []);

  const handleFitViewerCallback = useCallback(
    (type: string) => {
      switch (type) {
        case FitView.HEIGHT:
          pdfUtil?.viewer.fitHeight();
          break;
        case FitView.ORIGIN:
          pdfUtil?.viewer.resetZoom();
          break;
        default:
          pdfUtil?.viewer.fitWidth();
      }
    },
    [pdfUtil],
  );

  const onZoomChange = useCallback(
    throttle((scaleValue: number) => {
      !isUndefined(scaleValue) &&
        setTimeout(() => pdfUtil?.viewer.zoom(scaleValue / 100), 0);
    }, 100), // throttle 100ms to avoid rendering performance impact
    [pdfUtil],
  );

  useEffect(() => {
    const checkView3DModelButton = async () => {
      const modelUrl = await unlock3DLiteService.checkView3DModelAvailability(
        file.document_number,
        file.type_of_document ?? '',
        file.project_code ?? '',
      );
      setView3DModelButtonEnabled(!!modelUrl);
      setView3DModelUrl(modelUrl);
    };
    checkView3DModelButton();
  }, [file.document_number, file.type_of_document, file.project_code]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      className="pdf-detail detail-page z-[1501]"
      dialogClassName="modal-fullsize"
      onHide={onHide}
      onShow={onShow}
      show={props.show}
    >
      <div ref={modalHeaderRef}>
        <DetailHeader
          disabled={loadingState === 'loading'}
          file={file}
          findKeywordIndex={currentFindTermIndex}
          handleFitViewerCallback={handleFitViewerCallback}
          handleNextHighlight={handleNextHighlight}
          handlePreviousHighlight={handlePreviousHighlight}
          onHide={props.onHide}
          onScalePercentageChange={onZoomChange}
          pdfMatchedSearchTerms={matchedSearchTerms}
          pdfOrderedMatchedSearchTerms={orderedMatchedSearchTerms}
          scale={scale}
          selectedKeywords={selectedSearchTerms}
          toggleKeyword={toggleKeyword}
        />
        {loadingState === 'loading' && (
          <ProgressBar className="w-100" now={downloadFileProgress} />
        )}
      </div>
      <Modal.Body style={{ height: modalBodyHeight }}>
        {loadingState === 'error' ? (
          <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
            <BrokenImageIcon />
            <div>{t('document_preview_text_loading_failed')}</div>
          </div>
        ) : (
          <>
            {loadingState === 'loading' && (
              <Spinner
                animation="border"
                className="position-absolute"
                style={{ left: '50%', top: '50%' }}
              />
            )}
            {loadingState === 'success' && pdfUrl && showed && (
              <div
                className={[
                  'd-inline',
                  isProcessing ? 'is-processing' : '',
                ].join(' ')}
              >
                <PdfViewer
                  autoScale={false}
                  highlightToScroll={highlight}
                  onFocusHighlightChange={onFocusHighlightChange}
                  onScaleChange={onScaleChange}
                  pdfScale={'page-actual'}
                  pdfViewMode={'detail'}
                  positions={ocrData?.positions}
                  scrollable={true}
                  searchTerms={searchTerms}
                  setPdfViewerUtility={setPdfViewerUtility}
                  url={pdfUrl}
                  view3DModelButtonEnabled={view3DModelButtonEnabled}
                />
                {view3DModelButtonEnabled && view3DModelUrl && (
                  <View3DModelButtonFloat
                    documentNumber={file.document_number}
                    modelUrl={view3DModelUrl}
                  />
                )}
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
