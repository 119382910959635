import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { useTrackEvents } from '@/analytics/use-track-events';
import { fileService } from '@/services';

export default function DownloadButton({
  className,
  documentId = '',
  documentNo,
}: {
  className?: string;
  documentId: string;
  documentNo: string;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const { trackDownloadButtonClick } = useTrackEvents();

  if (!documentId) {
    return null;
  }

  return (
    <Button
      className={twMerge(
        'flex h-8 items-center gap-1 !bg-[#666] px-3 py-1 !text-sm !font-bold !normal-case text-white hover:!bg-[#333] active:!bg-[#111]',
        className,
      )}
      onClick={async () => {
        enqueueSnackbar('Downloading file', {
          className: '!bg-[#F5F5F5] !text-[#666] !w-fit !min-w-max',
        });
        await fileService.saveFile(documentId);

        trackDownloadButtonClick(documentNo);
      }}
      ref={anchorEl}
    >
      <svg
        fill="none"
        height="20"
        viewBox="0 0 20 20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          height="20"
          id="mask0_1002_2823"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="20"
          x="0"
          y="0"
        >
          <rect fill="#D9D9D9" height="20" width="20" />
        </mask>
        <g mask="url(#mask0_1002_2823)">
          <path
            d="M9.99999 12.9793C9.88888 12.9793 9.78472 12.962 9.68749 12.9272C9.59027 12.8925 9.49999 12.8335 9.41666 12.7502L6.41666 9.75016C6.24999 9.5835 6.17013 9.38905 6.17708 9.16683C6.18402 8.94461 6.26388 8.75016 6.41666 8.5835C6.58333 8.41683 6.78124 8.33002 7.01041 8.32308C7.23958 8.31613 7.43749 8.396 7.60416 8.56266L9.16666 10.1252V4.16683C9.16666 3.93072 9.24652 3.7328 9.40624 3.57308C9.56597 3.41336 9.76388 3.3335 9.99999 3.3335C10.2361 3.3335 10.434 3.41336 10.5937 3.57308C10.7535 3.7328 10.8333 3.93072 10.8333 4.16683V10.1252L12.3958 8.56266C12.5625 8.396 12.7604 8.31613 12.9896 8.32308C13.2187 8.33002 13.4167 8.41683 13.5833 8.5835C13.7361 8.75016 13.816 8.94461 13.8229 9.16683C13.8299 9.38905 13.75 9.5835 13.5833 9.75016L10.5833 12.7502C10.5 12.8335 10.4097 12.8925 10.3125 12.9272C10.2153 12.962 10.1111 12.9793 9.99999 12.9793ZM4.99999 16.6668C4.54166 16.6668 4.1493 16.5036 3.82291 16.1772C3.49652 15.8509 3.33333 15.4585 3.33333 15.0002V13.3335C3.33333 13.0974 3.41319 12.8995 3.57291 12.7397C3.73263 12.58 3.93055 12.5002 4.16666 12.5002C4.40277 12.5002 4.60069 12.58 4.76041 12.7397C4.92013 12.8995 4.99999 13.0974 4.99999 13.3335V15.0002H15V13.3335C15 13.0974 15.0799 12.8995 15.2396 12.7397C15.3993 12.58 15.5972 12.5002 15.8333 12.5002C16.0694 12.5002 16.2674 12.58 16.4271 12.7397C16.5868 12.8995 16.6667 13.0974 16.6667 13.3335V15.0002C16.6667 15.4585 16.5035 15.8509 16.1771 16.1772C15.8507 16.5036 15.4583 16.6668 15 16.6668H4.99999Z"
            fill="#FCFCFC"
          />
        </g>
      </svg>
      Download
    </Button>
  );
}
